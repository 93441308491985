<template v-if="search.can_user_select_dates">
    <div v-for="(guest, index) in search.data.selected_guests" :key="index" class="o-search__guest-toggle">
        <div :class="`c-amount-toggle is-${guest.type}`">
        
            <!-- Pets go free message -->
            <div v-if="guest.type === 'pet' && search.data.selected_parent_slug === 'touring-camping' && !isGlampingPodsSelected()"
                class="c-amount-toggle__title">
                Pets go free 
            </div>
            <div v-else>
                <div class="c-amount-toggle__title">
                {{ guest.description }}
                </div>

            <div class="c-amount-toggle__action-container">
                <!-- Minus Button: Disabled for pets when "touring-camping" is selected -->
                <div
                    @click="decrementGuest(guest)"
                    class="c-amount-toggle__action c-amount-toggle__action--less"
                    >
                </div>

                <div class="c-amount-toggle__amount">
                    {{ guest.number }}
                </div>

                <!-- Plus Button: Disabled for pets when "touring-camping" is selected -->
                <div
                    @click="incrementGuest(guest)"
                    class="c-amount-toggle__action c-amount-toggle__action--more"
                    >
                </div>
            </div>
            </div>
        </div>
    </div>

    <div class="text-center mt-2">
        <span class="text-center mt-2"><p>Call <a href="tel:01524701508" class="font-bold">01524 701508</a> for 6 or more people</p></span>
    </div>
    
</template>

<script setup>
import Users from "~/assets/icons/users.svg";
const search = useSearchStore();
const booking = useBookingStore();

const isGlampingPodsSelected = () => {
    return search.availability_rules.rules.some(rule => rule.filter === 'Glamping Pods');
};



// Function to get the total number of human guests (excluding pets)
const getTotalHumanGuests = () => {
    return search.data.selected_guests
        .filter(guest => guest.type !== 'pet') // Exclude pets
        .reduce((total, guest) => total + guest.number, 0);
};

// Function to get the total number of pets
const getTotalPets = () => {
    return search.data.selected_guests
        .find(guest => guest.type === 'pet')?.number || 0; // Get pet count or default to 0
};

// Ensure pets remain disabled and set to 0 when `touring-camping` is selected
watch(() => search.data.selected_parent_slug, (newSlug) => {
    const hasFilter14 = search.data.selected_child_filters.includes(14);
    if (!hasFilter14) {
        const petGuest = search.data.selected_guests.find(guest => guest.type === 'pet');
        if (petGuest) {
            petGuest.number = 0; // Ensure pets remain 0
        }
        booking.order.pets = 0; // Ensure pets in the order remain 0
    }
});

// Function to decrease guest count
const decrementGuest = (guest) => {
    if (guest.number > 0) {
        guest.number--;
    }
};

// Function to increase guest count
const incrementGuest = (guest) => {
    const totalHumans = getTotalHumanGuests();
    const totalPets = getTotalPets();
    const hasFilter14 = search.data.selected_child_filters.includes(14);
    let maxPets = 2
    

    // Enforce separate limits for humans (6 max) and pets (2 max by default, 1 if Glamping Pods)
    if (guest.type === 'pet') {
        if (hasFilter14) {
            maxPets = isGlampingPodsSelected() ? 1 : 2;
            if (totalPets < maxPets) {
                guest.number++;
            }
        } else {
            if (totalPets < maxPets) {
                guest.number++;
            }
        }
    } else {
        if (totalHumans < 6) {
            guest.number++;
        }
    }
};

</script>

<style scoped>
/* Disable buttons visually */
.is-disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
